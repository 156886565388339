/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import favicon3 from "./favicon3.png"

const siteBasic = {
  title: "",
  description: "",
  lang: "",
}

const seoBasic = {
  title: "Page",
  opengraphTitle: "",
  metaKeywords: "",
  metaDesc: "",
  metaRobotsNofollow: "",
  metaRobotsNoindex: "",
  opengraphDescription: "",
  opengraphImage: null,
}

function Head({ siteMetaData = {}, seo = {}, ogType = "website", ogImage }) {
  return (
    <Helmet
      htmlAttributes={{
        lang: siteMetaData.language || siteBasic.lang,
      }}
      title={seo.title || siteBasic.title}
      meta={[
        {
          name: `description`,
          content: seo.opengraphDescription || siteMetaData.description,
        },
        {
          name: `keywords`,
          content: seo.focuskw || "",
        },
        {
          property: `og:title`,
          content: seo.title || siteMetaData.title || siteBasic.description,
        },
        {
          property: `og:description`,
          content:
            seo.opengraphDescription ||
            siteMetaData.description ||
            siteBasic.description,
        },
        {
          property: `og:image`,
          content: seo.opengraphImage ? seo.opengraphImage.sourceUrl : ogImage,
        },
        {
          property: `og:image:alt`,
          content: seo.title,
        },
        {
          property: `og:type`,
          content: ogType,
        },
      ]}
    >
      {/* favicon */}
      <link rel="icon" href={favicon3} />

      <link
        async
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.4.1/css/bootstrap.min.css"
      />
      <link
        async
        href="https://fonts.googleapis.com/css?family=Aleo:300,400,600,700&display=swap"
        rel="stylesheet"
      />
      <link
        async
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,700,800&display=swap"
        rel="stylesheet"
      />
      {/* zamienić na bootstrap grid albo mniejszą libkę do grida, albo napisać taką do własnych zastosowań */}
      {/* Iconfont cdn page not available */}
      {/* <link
        async
        rel="stylesheet"
        href="https://allyoucan.cloud/cdn/icofont/1.0.1/icofont.css"
      /> */}
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css"
        integrity="sha512-xh6O/CkQoPOWDdYTDqeRdPCVd1SpvCA9XXcUnZS2FmJNp1coAFzvtCN9BmamE+4aHK8yyUHUSCcJHgXloTyT2A=="
        crossorigin="anonymous"
        referrerpolicy="no-referrer"
      />
      <link
        async
        defer
        rel="stylesheet"
        type="text/css"
        charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        async
        defer
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      {/* <script async defer type="text/javascript">{`
               window.replainSettings = { id: 'ba059d89-40a8-4adc-89b8-c819e85c810b' };
               (function(u){var s=document.createElement('script');s.type='text/javascript';s.async=true;s.src=u;
               var x=document.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);
               })('https://widget.replain.cc/dist/client.js');
    `}</script> */}
    </Helmet>
  )
}

// SEO.defaultProps = {
//   lang: `en`,
//   meta: [],
//   description: ``,
// }

// SEO.propTypes = {
//   description: PropTypes.string,
//   lang: PropTypes.string,
//   meta: PropTypes.arrayOf(PropTypes.object),
//   title: PropTypes.string.isRequired,
// }

export default Head
