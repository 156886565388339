import React from "react"
import "./modal.scss"

class Modal extends React.Component {
  state = { imOpen: false }
  toggle = () => {
    this.setState({
      imOpen: !this.state.imOpen,
    })
  }
  render() {
    const { title = "Modal Header" } = this.props
    if (!this.state.imOpen) {
      return null
    }

    return (
      <div className="app-modal magictime fadeIn">
        <button className="app-modal-close" onClick={this.toggle}>
          <i className="icofont-close"></i>
        </button>
        <a className="app-modal-bg" onClick={this.toggle}></a>

        <div className="app-modal-inner container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10">
              {title && (
                <div className="app-modal-header">
                  <h3>{title}</h3>
                </div>
              )}
              <div className="container">{this.props.children}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Modal
