import React, { useState } from "react"
import Slider from "react-slick"
import { truncate } from "lodash"
import { Link } from "gatsby"
import { ResizedImage as Image, Observer } from ".."
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const settings = {
  infinite: true,
  arrows: true,
  slidesToShow: 4,
  dotsClass: "slick-dots slick-thumb",
  slidesToScroll: 1,
  autoplay: true,
  speed: 400,
  autoplaySpeed: 5000,
  cssEase: "ease-in-out",
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const calculateActiveIdx = (idx, steps) => {
  if (idx <= steps) {
    return idx
  } else {
    return idx - 1 - steps
  }
}

const TherapySlide = props => {
  const { image, slug, name, active = false, description } = props
  return (
    <div className={["therapy-slide", active ? "active" : ""].join(" ")}>
      <Link
        to={`/categories/${slug}/`}
        onClick={() => {
          trackCustomEvent({
            category: "Product - ".concat(name),
            action: "Product selection",
            label: "Product selection",
          })
        }}
      >
        <div className={"therapy-slide-image"}>
          <Image {...image} size={"150w"} />
        </div>
        <div className="d-md-none">
          <h6 className="therapy-slide-title">
            <strong>{name}</strong>
          </h6>
        </div>
        <div className="d-none d-md-block">
          {active ? (
            <h6 className="therapy-slide-title">
              <strong>{name}</strong>
            </h6>
          ) : (
            <h6 className="therapy-slide-title">{name}</h6>
          )}
        </div>
        <div
          className="d-md-none therapy-slide-description"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
      </Link>
    </div>
  )
}

const TherapyDescription = ({ image, slug, name, description }) => {
  return (
    <div className="therapy-description d-none d-md-block" id={slug}>
      <div className="row">
        <div className="col-sm-6 col-12">
          <Image {...image} size={"300w"} />
        </div>
        <div className="col-sm-6 col-12">
          <h3 className="therapy-description-title">{name}</h3>
          <h5
            className="therapy-description-content"
            dangerouslySetInnerHTML={{
              __html: truncate(description, { length: 200 }),
            }}
          ></h5>
          <Link
            className="therapy-description-link"
            to={`/categories/${slug}/`}
            onClick={() => {
              trackCustomEvent({
                category: "Show prodcucts for - ".concat(slug),
                action: "Show products",
                label: "Show Products",
              })
            }}
          >
            Zobacz produkty
          </Link>
        </div>
      </div>
    </div>
  )
}

const TherapySlider = ({ data }) => {
  const [idx, setIdx] = useState(1)
  return (
    <div className="therapy-slider">
      <TherapyDescription {...data[idx]} />
      <Slider
        {...settings}
        afterChange={ev => {
          setIdx(calculateActiveIdx(ev + 1, data.length - 1))
        }}
      >
        {data.map((el, k) => {
          return (
            <div key={k}>
              <TherapySlide {...el} active={idx} />
            </div>
          )
        })}
      </Slider>
    </div>
  )
}

export default TherapySlider
