const getSortedSrcSet = srcSet => {
  try {
    var regexConst = new RegExp("[0-9]{1,4}x[0-9]{1,4}", "g")
    return (
      srcSet
        .split(", ")
        .map(el => el.split(" "))
        .sort((a, b) => {
          return Number(a[1].replace("w", "")) > Number(b[1].replace("w", ""))
            ? 1
            : -1
        })
        .map(el => {
          const sizes = el[0].match(regexConst)
          if (sizes) {
            const [dims] = sizes
            el.push(dims.split("x").map(Number))
          } else {
            el.push(null)
          }
          return el
        }) || []
    )
  } catch (err) {
    // ignore
    return []
  }
}

const getCssSizes = sortedSizes => {
  return sortedSizes
    .reduce((acc, curr) => {
      acc.push(
        `(max-width: ${curr[1].replace("w", "px")}) ${curr[1].replace(
          "w",
          "px"
        )}`
      )
      return acc
    }, [])
    .join(", ")
}

export { getSortedSrcSet, getCssSizes }
