import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { Cart, Search } from ".."

const MobileMenu = () => {
  const [isOpen, toggle] = useState(false)
  const { WP } = useStaticQuery(graphql`
    query {
      WP {
        menu(id: "TWVudToxNg==") {
          menuItems {
            nodes {
              url
              label
            }
          }
        }
      }
    }
  `)
  const { nodes } = WP.menu.menuItems

  return (
    <div className="app-mobile-menu-wrapper">
      {isOpen && (
        <a
          className="app-mobile-menu-bg"
          onClick={() => {
            toggle(!isOpen)
          }}
        >
          <i className="icofont-navigation-menu"></i>
        </a>
      )}
      <div className="app-mobile-menu-toggler">
        <Search />
        <Cart />
        <div
          className={`menu-hamburger ${isOpen ? "active" : ""}`}
          onClick={() => {
            toggle(!isOpen)
          }}
        >
          {/* <i className="fa-solid fa-bars"></i> */}
          <img
            src="http://greendjinn.api.localhost-group.com/wp-content/uploads/2022/10/lamp.png"
            alt=""
          />
        </div>
      </div>

      {isOpen && (
        <ul className="app-mobile-menu magictime fadeIn">
          {nodes.map((el, k) => {
            return (
              <li key={k}>
                <Link
                  activeClassName="active"
                  to={`${el.url}`}
                  onClick={() => {
                    trackCustomEvent({
                      category: "Menu - ".concat(el.label),
                      action: "Menu item selection",
                      label: "Menu selection",
                    })
                  }}
                >
                  {el.label}
                </Link>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default MobileMenu
