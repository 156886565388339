import React, { useRef } from "react"
import { WindowCtx } from "../../../reduxWrapper"
import "./mandala.scss"

const img =
  "https://greendjinn.api.localhost-group.com/wp-content/uploads/2020/03/mar.jpg"

const onScroll = ({ height, width }, { x, y }, ref) => {
  if (ref.current) {
    const { offsetTop, offsetLeft, offsetWidth, offsetHeight } = ref.current

    const diff =
      ((offsetTop + offsetHeight - y) / (offsetTop + offsetHeight)) * 100
    return 50 + diff + "%"
  } else {
    return "50%"
  }
}

const Mandala = ({ image = img, children, height = 500 }) => {
  const ref = useRef(null)

  return (
    <WindowCtx.Consumer>
      {value => (
        <div
          ref={ref}
          className={"mandala"}
          style={{
            height: height,
            backgroundRepeat: "no-repeat",
            backgroundPositionX: "100%",
            backgroundPositionY: onScroll(value.window, value.scroll, ref),
          }}
        >
          {children}
        </div>
      )}
    </WindowCtx.Consumer>
  )
}

export default Mandala
