import React, { useRef, useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link } from "gatsby"
import { ResizedImage, Modal } from "../"
import { cartActions } from "@stores"
import BasketIcon from "./koszyk.svg"
import { WPApi } from "@api"

import "./cart.scss"

const Icon = props => {
  const { name = "koszyk", width = 10, height = 10 } = props
  if (name.includes("koszyk")) {
    return <BasketIcon {...props} />
  }
  return null
}

const CartItem = ({
  image,
  quantity,
  name,
  value,
  product_id,
  changeProductQty,
  unit,
  removeFromCart,
}) => {
  return (
    <div className="cart-item col-6 col-md-4">
      <div className="cart-item-image">
        <ResizedImage {...image} />
        <a
          className="cart-item-delete"
          onClick={() => {
            removeFromCart({ product_id })
          }}
        >
          <i className="icofont-close"></i>
        </a>
      </div>
      <div className="cart-item-description">
        <h6>{name}</h6>
        <h6>
          {value} <small>{unit}</small>
        </h6>
        <h6>
          <button
            onClick={() => {
              changeProductQty({ product_id, quantity: quantity - 1 })
            }}
          >
            <i className="fa-solid fa-minus"></i>
          </button>
          {quantity}
          <button
            onClick={() => {
              changeProductQty({ product_id, quantity: quantity + 1 })
            }}
          >
            <i className="fa-solid fa-plus"></i>
          </button>
        </h6>
      </div>
    </div>
  )
}

const Cart = props => {
  const modal = useRef()

  const {
    store,
    resetCart,
    changeProductQty,
    removeFromCart,
    setCoupon,
  } = props
  const { items, sum, unit, coupon } = store
  const [state, setState] = useState({ coupons: [], categories: [] })

  let urlCoupon = null
  if (typeof window !== "undefined") {
    urlCoupon = localStorage.getItem("urlCoupon")
  }

  if (state.coupons.length == 0) {
    WPApi.getAllCoupons().then(data => {
      setState({
        ...state,
        coupons: data,
      })
    })
  }

  useEffect(() => {
    if (state.categories.length === 0) {
      WPApi.getAllProductCategories().then(data => {
        setState({
          ...state,
          categories: data,
        })
      })
    }
  }, [state.categories])

  useEffect(() => {
    if (urlCoupon) {
      setCoupon(urlCoupon)
    }
  }, [urlCoupon])

  let couponType = null
  let couponAmount = 0
  let couponProductsIds = null
  let excludedProductIds = null
  let edcludedProductCategories = null

  if (state.coupons) {
    if (coupon) {
      state.coupons.map((el, k) => {
        if (el.code == coupon) {
          if (el.product_ids.length > 0) {
            couponProductsIds = el.product_ids
          }
          couponType = el.discount_type
          couponAmount = el.amount
          console.log("el", el)
          if (el.excluded_product_ids.length > 0) {
            excludedProductIds = el.excluded_product_ids
          }
          if (el.excluded_product_categories.length > 0) {
            edcludedProductCategories = el.excluded_product_categories
          }
        }
      })
    }
  }

  let percentageCoupon = 0
  if (couponAmount) {
    if (couponType === "percent") {
      percentageCoupon = (couponAmount / 100).toFixed(2)
    }
  }

  let fixedCoupon = 0
  if (couponAmount) {
    if (couponType === "fixed_cart") {
      fixedCoupon = couponAmount
    }
  }

  let couponCalculated = 0
  if (percentageCoupon != 0) {
    if (couponProductsIds) {
      items.forEach(el => {
        if (couponProductsIds.includes(el.product_id)) {
          couponCalculated += el.value * percentageCoupon
        }
      })
    }
    if (excludedProductIds) {
      items.forEach(el => {
        if (excludedProductIds.includes(el.product_id) === false) {
          couponCalculated += el.value * percentageCoupon
        }
      })
    } else {
      couponCalculated = sum.val * percentageCoupon
    }
  }
  if (fixedCoupon != 0) {
    couponCalculated = fixedCoupon
  }

  if (items.length === 0) {
    return (
      <div className="cart">
        <div className="cart-toggler">
          <Link
            activeClassName="active"
            className="app-menu-btn"
            to="/categories/"
          >
            <img src={BasketIcon} />
          </Link>
        </div>
      </div>
    )
  }

  return (
    <div className="cart">
      <div className="cart-toggler">
        <button
          className="app-menu-btn"
          onClick={() => {
            modal.current.toggle()
          }}
        >
          <img src={BasketIcon} />
          <span>{items.length}</span>
        </button>
      </div>
      <Modal title={""} ref={modal}>
        <div className="cart-header">
          <h3>Koszyk</h3>
          <button
            onClick={() => {
              resetCart()
            }}
          >
            Wyczyść
          </button>
        </div>
        <div className="cart-modal">
          <div className="cart-items row">
            {items.map((el, k) => (
              <CartItem
                {...el}
                key={k}
                changeProductQty={changeProductQty}
                removeFromCart={removeFromCart}
              />
            ))}
          </div>
        </div>
        {/* desktop */}
        <div className="d-none d-md-block">
          <div className="cart-footer">
            <div>
              <input
                type="text"
                placeholder="Mam kupon rabatowy"
                value={coupon}
                onChange={ev => setCoupon(ev.target.value)}
              ></input>
              {couponCalculated != 0 ? (
                <h5>Wartość kuponu: {couponCalculated} PLN</h5>
              ) : (
                <h5>&nbsp;</h5>
              )}
            </div>
            <h2>
              Razem:{" "}
              <span>
                {sum.val} <small>{unit}</small>
              </span>
            </h2>

            <Link to="/checkout/">Do płatności</Link>
          </div>
        </div>
        {/* mobile */}
        <div className="d-md-none">
          <div className="cart-footer">
            <div className="row">
              <h2>
                Razem:{" "}
                <span>
                  {sum.val} <small>{unit}</small>
                </span>
              </h2>
            </div>
            <div className="row">
              <Link to="/checkout/">Do płatności</Link>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = state => {
  return { store: state.Cart }
}

const mapActionToDispatch = { ...cartActions }

export default connect(mapStateToProps, mapActionToDispatch)(Cart)
