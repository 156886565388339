import React from "react"
import {
  BottomMenu,
  ResizedImage as Image,
  SocialLinks,
  ContactBlock,
} from "../"
import { useStaticQuery, graphql, Link } from "gatsby"
import "./footer.scss"

export const Footer = () => {
  const { WP } = useStaticQuery(graphql`
    query {
      WP {
        pageBy(uri: "home") {
          ACF_footer {
            footer {
              phone
              street
              zipCode
              email
            }
          }
          ACF_contact {
            contact {
              title
              person1
              person2
            }
          }
        }
        mediaItemBy(slug: "hrenneps") {
          sourceUrl
          srcSet
          altText
        }
      }
    }
  `)

  const { footer } = WP.pageBy.ACF_footer
  const { contact } = WP.pageBy.ACF_contact

  return (
    <React.Fragment>
      <footer className="footer">
        <div className="container pt-5">
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-lg-3 pb-3">
              <div className="logo mb-3">
                <Link to={"/"}>
                  <Image {...WP.mediaItemBy} size={"300w"} />
                </Link>
              </div>
              <SocialLinks />
            </div>
            <div className="col-xs-12 col-md-6 col-lg-6 pb-3">
              <ContactBlock
                title={contact.title}
                person1={contact.person1}
                person2={contact.person2}
                street={footer.street}
                zipCode={footer.zipCode}
                email={footer.email}
              />
            </div>
            <div className="col-xs-12 col-md-6 col-lg-3 pb-3">
              <BottomMenu />
            </div>
            {/*
					<div className='col-xs-12 col-sm-12 col-md-4'>
						<ContactForm />
					</div>
					*/}
          </div>
        </div>
      </footer>
      <div className="footer-author">
        <div className="container">
          <div className="row">
            <div className="col">
              <a href="https://academy.localhost-group.com/">
                Wykonanie: Localhost Group
              </a>
            </div>
            <div className="col  text-right">
              <a href="https://biuroreklama.pl/">DESIGN: BIURO REKLAMA</a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Footer
