import React from "react"
import styles from "./styles.module.scss"

const {
  wrapper,
  wrapperTitle,
  wrapperAddress,
  wrapperEmail,
  wrapperPerson,
} = styles

const ContactBlock = ({ title, street, zipCode, email, person1, person2 }) => {
  return (
    <div className={wrapper}>
      <h6 className={wrapperTitle}>{title}</h6>
      <h6 className={wrapperAddress}>{street}</h6>
      <h6 className={wrapperAddress}>{zipCode}</h6>
      <h6 className={wrapperEmail}>
        <a href={`mailto:${email}`}>{email}</a>
      </h6>
      {/* <hr /> */}
      <div
        className={wrapperPerson}
        dangerouslySetInnerHTML={{ __html: person1 }}
      ></div>
      {/* <hr /> */}
      <div
        className={wrapperPerson}
        dangerouslySetInnerHTML={{ __html: person2 }}
      ></div>
    </div>
  )
}

export default ContactBlock
