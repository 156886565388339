import React, { useEffect, useRef, useState } from "react"
import { Head, Layout, Modal, TextInput } from "@common"
import { WPApi } from "@api"

import "./popup.scss"

const EmailPopUp = () => {
  const [state, setState] = useState({ email: "", imBusy: false, msg: "" })
  const emailModal = useRef()
  let visitorEmail = null

  if (typeof window !== "undefined") {
    visitorEmail = localStorage.getItem("visitorEmail")
  }
  useEffect(() => {
    if (!visitorEmail) {
      const timer = setTimeout(() => {
        emailModal.current.toggle()
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [])

  const closeAndSave = () => {
    localStorage.setItem("visitorEmail", state.email)
    setState({ ...state, imBusy: true })
    WPApi.signByPopUp(state.email).then(data => {
      setState({
        email: "",
        msg: data.message,
        imBusy: false,
      })
    })
    if (!state.imBusy) {
      const timer = setTimeout(() => {
        emailModal.current.toggle()
      }, 5000)
      return () => clearTimeout(timer)
    }
  }

  return (
    <Modal
      title="Chcesz, żeby Djinn spełnił Twoje marzenia, przekazywał swoją wiedzę i wysyłał prezenty?"
      ref={emailModal}
    >
      <h5>Podaj mi swój e-mail, a życzenie Twe uczynię!</h5>
      <div className="popup">
        <div className="popup-form">
          <form
            onSubmit={ev => {
              ev.preventDefault()
            }}
          >
            <TextInput
              autofocus={true}
              value={state.email}
              onChange={ev => {
                setState({
                  email: ev.target.value,
                })
              }}
              placeholder={"Twój adres email"}
            />
          </form>
        </div>
        <button onClick={() => closeAndSave()}> wyślij </button>
        {state.msg && <h5>{state.msg}</h5>}
      </div>
    </Modal>
  )
}

export default EmailPopUp
