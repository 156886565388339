import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import {
  wrapper,
  person,
  personImgContainer,
  personInfo,
  img,
} from "./persons.module.scss"

const Persons = () => {
  const { WP } = useStaticQuery(graphql`
    query {
      WP {
        pageBy(uri: "kontakt") {
          ACF_contact {
            contact {
              person1
              personimg1
              person2
              personimg2
            }
          }
        }
      }
    }
  `)

  const { contact } = WP.pageBy.ACF_contact
  const { person1, personimg1, person2, personimg2 } = contact

  return (
    <div className={wrapper}>
      <div className={person}>
        <div className={personImgContainer}>
          <img className={img} src={personimg1} alt="Person" />
        </div>
        <div
          className={personInfo}
          dangerouslySetInnerHTML={{
            __html: person1,
          }}
        />
      </div>
      <div className={person}>
        <div className={personImgContainer}>
          <img className={img} src={personimg2} alt="Person" />
        </div>
        <div
          className={personInfo}
          dangerouslySetInnerHTML={{
            __html: person2,
          }}
        />
      </div>
    </div>
  )
}

export default Persons
