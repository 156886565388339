import React from "react"

import styles from "./styles.module.scss"

const { wrapper, link } = styles

const SocialLinks = () => {
  return (
    <div className={wrapper}>
      <a
        className={link}
        href="https://www.facebook.com/Green-Djinn-748462858883415/"
        target="_blank"
      >
        <i className="fa-brands fa-facebook"></i>
      </a>
      <a
        className={link}
        href="https://www.instagram.com/green_djinn_medicine/?hl=pl"
        target="_blank"
      >
        <i className="fa-brands fa-instagram"></i>
      </a>
    </div>
  )
}

export default SocialLinks
