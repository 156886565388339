import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { TopMenu, MobileMenu, ResizedImage as Image } from "../"
import "./header.scss"

const Header = () => {
  const { WP } = useStaticQuery(graphql`
    query {
      WP {
        mediaItemBy(slug: "logo-new-shadow2") {
          sourceUrl
          srcSet
          altText
        }
      }
    }
  `)

  return (
    <div className="app-top-menu">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-6">
            <div className="logo">
              <Link to={"/"}>
                <Image {...WP.mediaItemBy} size={"300w"} />
              </Link>
            </div>
          </div>

          <div className="col-6 d-md-none">
            <MobileMenu />
          </div>

          <div className="col-md-9 d-none d-md-block">
            <TopMenu />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
