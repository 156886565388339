import React from "react"
import { WPApi } from "@api"
import { TextInput } from ".."
import { StaticQuery, graphql } from "gatsby"

import is from "is_js"

class NewsletterForm extends React.Component {
  state = {
    email: "",
    msg: "",
    imBusy: false,
  }

  action = () => {
    if (is.email(this.state.email) === true) {
      this.setState({
        imBusy: true,
      })
      WPApi.signToNewsletter(this.state.email).then(data => {
        this.setState({
          email: "",
          msg: data.message,
          imBusy: false,
        })
      })
    }
  }

  render() {
    if (this.state.imBusy) {
      return (
        <div className="form-group">
          <h6 className="subtitle">Dodajemy Cię do listy mailingowej.</h6>
        </div>
      )
    }
    if (this.state.msg != "") {
      return (
        <div className="form-group">
          <h6 className="subtitle">{this.state.msg}</h6>
        </div>
      )
    }

    return (
      <React.Fragment>
        <div className="container">
          <form className="row  align-items-center">
            <div className="col-12 col-md-6 ">
              <h3 className="title">Zapisz się do newslettera</h3>
              <h6 className="subtitle">
                Będziemy wysyłać Ci nową wiedzę, analizy badań oraz ciekawe
                artykuły na tematy dotyczące stosowania konopii w leczeniu
              </h6>
            </div>
            <div className="col-12 col-md-6">
              <div className="newsletter-form">
                <TextInput
                  type="email"
                  value={this.state.email}
                  placeholder="Wprowadź adres email"
                  onChange={ev => {
                    const { value } = ev.target
                    this.setState({
                      email: value,
                    })
                  }}
                />
                <button className="button" onClick={this.action}>
                  Zapisz się
                </button>
              </div>
            </div>
            <div className="col-12">
              <small>
                <a href="/polityka-prywatnosci">
                  * Zapisując się do newslettera zgadzasz się na przetwarzanie
                  swoich informacji osobowych zgodnie z naszą Polityką
                  Prywatności.
                </a>
              </small>
            </div>
          </form>
        </div>
      </React.Fragment>
    )
  }
}

export default NewsletterForm
