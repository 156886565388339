import { WPApi } from "@api"

const createReview = (id, form) => {
  return WPApi.createProductReview(id, form)
}

const retrieveReviews = id => {
  return WPApi.retrieveProductReview(id)
}

export { createReview, retrieveReviews }
