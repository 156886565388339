import React, { useState } from "react"
import Slider from "react-slick"
import { truncate } from "lodash"
import { Link } from "gatsby"
import { ResizedImage as Image, Observer } from ".."
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const settings = {
  infinite: true,
  arrows: true,
  slidesToShow: 1,
  dotsClass: "slick-dots slick-thumb",
  slidesToScroll: 1,
  autoplay: true,
  speed: 400,
  autoplaySpeed: 5000,
  cssEase: "ease-in-out",
}

const TherapySlideMobile = props => {
  const { image, slug, name, description } = props
  return (
    <div className="therapy-slide">
      <Link
        to={`/categories/${slug}/`}
        onClick={() => {
          trackCustomEvent({
            category: "Product - ".concat(name),
            action: "Product selection",
            label: "Product selection",
          })
        }}
      >
        <div className={"therapy-slide-image"}>
          <Image {...image} size={"150w"} />
        </div>
        <div>
          <h6 className="therapy-slide-title">
            <strong>{name}</strong>
          </h6>
        </div>
        <div
          className="therapy-slide-description"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
      </Link>
    </div>
  )
}

const TherapySliderMobile = ({ data }) => {
  return (
    <div className="therapy-slider">
      <Slider {...settings}>
        {data.map((el, k) => {
          return (
            <div key={k}>
              <TherapySlideMobile {...el} />
            </div>
          )
        })}
      </Slider>
    </div>
  )
}

export default TherapySliderMobile
