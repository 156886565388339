import React from "react"
import { Header, Footer, Loader, Parallax, Observer, Modal } from "../"
import "./layout.scss"
import { useStaticQuery, graphql, Link } from "gatsby"
import { setCookie, getCookie } from "./logic"
import { Questions } from "./Questions"
import { truncate } from "lodash"

class Layout extends React.Component {
  // modal = React.createRef()

  // componentDidMount() {
  // 	if (getCookie('questions') != 'false') {
  // 		this.modal.current.toggle()
  // 	}
  // }

  render() {
    const {
      children,
      animation = true,
      imBusy = false,
      title = false,
      subtitle = false,
      parallaxImg = {},
    } = this.props

    return (
      <React.Fragment>
        <div className="app container-fluid">
          <Loader show={imBusy} />
          <Header />
          {/* <Modal title={'Co Cię do mnie sprowadza Wędrowcze?'} ref={this.modal} withClose={false}>
						<div className="questions">
							<Questions />
						</div>
						<div className="questions-quit">
						<a href="/" onClick={() => setCookie('questions', 'false', 86400)}><h5>Twoje pytanie jest wyjątkowe i nie znalazłeś na nie odpowiedzi? Zapytaj Djinna na czacie!</h5></a>

						</div>
					</Modal> */}
          {title && (
            <Parallax image={parallaxImg} height={350}>
              <div className="container">
                <h1 dangerouslySetInnerHTML={{ __html: title }} />
                {subtitle && (
                  <h4
                    dangerouslySetInnerHTML={{
                      __html: truncate(subtitle, { length: 300 }),
                    }}
                  />
                )}
              </div>
            </Parallax>
          )}
          <main className={["app-page", animation ? "" : ""].join(" ")}>
            {children}
          </main>
          <Footer />
        </div>
      </React.Fragment>
    )
  }
}

export default Layout
