import React from "react"

import {
  delivery,
  delivery__buttons,
  delivery__buttons__item,
  delivery__buttons__item__input,
  delivery__buttons__item__input__radio__item__label,
} from "./delivery.module.scss"

const DeliveryRadioButtons = ({ items = [], itemMapper = el => el }) => {
  return (
    <div className={delivery}>
      <div className={delivery__buttons}>
        {items.map((el, key) => {
          const item = itemMapper(el)
          return (
            <div key={key} className={delivery__buttons__item}>
              <input
                className={delivery__buttons__item__input}
                type="radio"
                {...item}
              />
              <label
                className={delivery__buttons__item__input__radio__item__label}
                onClick={item.onChange}
              >
                {item.name}
              </label>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default DeliveryRadioButtons
