import React from "react"
import Slider from "react-slick"
import { Link } from "gatsby"
import { ResizedImage as Image } from ".."
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const categorySettings = {
  infinite: true,
  arrows: true,
  slidesToShow: 4,
  dotsClass: "slick-dots slick-thumb",
  slidesToScroll: 1,
  autoplay: true,
  speed: 400,
  autoplaySpeed: 5000,
  cssEase: "ease-in-out",
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const CategorySlide = ({ image, slug, name }) => {
  return (
    <div className="category-slide">
      <Link
        to={`/categories/${slug}/`}
        onClick={() => {
          trackCustomEvent({
            category: "Category - ".concat(slug),
            action: "Category selection",
            label: "Category selection",
          })
        }}
      >
        <div className="category-slide-image">
          <Image {...image} size={"300w"} />
        </div>
        <h5 className="category-slide-title">{name}</h5>
      </Link>
    </div>
  )
}

const CategorySlider = ({ data }) => {
  return (
    <div className="category-slider">
      <Slider {...categorySettings}>
        {data.map((el, k) => (
          <CategorySlide key={k} {...el} />
        ))}
      </Slider>
    </div>
  )
}

export default CategorySlider
