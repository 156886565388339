import React from "react"
import { WPApi } from "../../../api"
import { TextInput } from "../"
import is from "is_js"
import "./contact.scss"

class ContactForm extends React.Component {
  state = {
    email: "",
    content: "",
    msg: "",
    imBusy: false,
  }
  action = () => {
    if (is.email(this.state.email) === true) {
      this.setState({
        imBusy: true,
      })
      WPApi.sendMail(this.state.email, this.state.content).then(
        ({ message }) => {
          this.setState({
            email: "",
            content: "",
            msg: message,
            imBusy: false,
          })
        }
      )
    }
  }
  render() {
    let disabled = false
    if (this.state.email == "" || this.state.content == "") {
      disabled = true
    }
    if (this.state.imBusy) {
      return (
        <div className="form-group">
          <h6>Wysyłanie wiadomości, proszę czekać</h6>
        </div>
      )
    }
    return (
      <div className="contact">
        <div className="form contact-form">
          <form className="row justify-content-center">
            <div className="col-xs-12 col-md-12">
              <TextInput
                type="email"
                value={this.state.email}
                placeholder="Wprowadź swój e-mail"
                onChange={ev => {
                  const { value } = ev.target
                  this.setState({
                    email: value,
                  })
                }}
              />
              <div className="form-group">
                <textarea
                  className="form-control"
                  value={this.state.content}
                  placeholder="Wprowadź treść wiadomości"
                  onChange={ev => {
                    const { value } = ev.target
                    this.setState({
                      content: value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-xs-12 col-md-12">
              <div className="row justify-content-center">
                <button
                  disabled={disabled}
                  className="contact-button"
                  onClick={this.action}
                >
                  Wyślij
                </button>
              </div>
            </div>
            <div>
              <h6 className="contact-message">{this.state.msg}</h6>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default ContactForm
