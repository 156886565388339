import React from "react"

class Stepper extends React.Component {
  state = {
    actualIndex: 0,
  }

  setIndex = (idx, withScroll) => {
    this.setState(
      {
        actualIndex: idx,
      },
      () => {
        if (withScroll) {
          window.scrollTo({
            top: 200,
            left: 0,
            behavior: "smooth",
          })
        }
      }
    )
  }

  render() {
    const { children, withScroll = false } = this.props
    if ([undefined, null].includes(children)) {
      return "No children"
    }

    const buttons = React.Children.map(children, (child, key) => {
      if (child) {
        return (
          <div className="col d-flex justify-content-center">
            <button
              onClick={() => {
                this.setIndex(key, withScroll)
              }}
              disabled={child.props.disabled}
              className={
                this.state.actualIndex === key ? "app-button" : "app-button alt"
              }
            >
              {child.props.title}
            </button>
          </div>
        )
      }
      return null
    })

    const view = React.Children.map(children, child => {
      if (child) {
        return React.cloneElement(child, {
          className: child.props.className + " magictime vanishIn",
        })
      } else {
        return null
      }
    })[this.state.actualIndex]

    return (
      <div>
        {buttons.length > 1 && <div className="row">{buttons}</div>}
        <div className="row">{view}</div>
      </div>
    )
  }
}

export default Stepper
