import React from "react"
import Slider from "react-slick"
import { Link } from "gatsby"
import { truncate } from "lodash"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { ResizedImage as Image } from ".."

const blogSettings = {
  infinite: true,
  arrows: true,
  slidesToShow: 3,
  dotsClass: "slick-dots slick-thumb",
  slidesToScroll: 1,
  autoplay: true,
  speed: 400,
  autoplaySpeed: 5000,
  cssEase: "ease-in-out",
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const BlogSlide = ({ featuredImage, slug, title, content }) => {
  return (
    <div className="blog-slide">
      <Link
        to={`/blog/${slug}/`}
        onClick={() => {
          trackCustomEvent({
            category: "Post - ".concat(slug),
            action: "Post selection",
            label: "Post selection",
          })
        }}
      >
        <div className="blog-slide-image">
          <Image {...featuredImage} size={"600w"} />
          <div className="blog-slide-inner">
            <div className="blog-slide-title">
              <h5
                dangerouslySetInnerHTML={{
                  __html: truncate(title, { length: 50 }),
                }}
              />
            </div>
            <button className="blog-slide-button">Zobacz więcej</button>
          </div>
        </div>
      </Link>
    </div>
  )
}

const BlogSlider = ({ data }) => {
  return (
    <div className="blog-slider">
      <Slider {...blogSettings}>
        {data.map((el, k) => (
          <BlogSlide key={k} {...el} />
        ))}
      </Slider>
    </div>
  )
}

export { BlogSlide }
export default BlogSlider
