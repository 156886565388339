import React, { useRef, useEffect, useMemo, useContext } from "react"
import memoize from "fast-memoize"
import { WindowCtx } from "../../../reduxWrapper"
import { getSortedSrcSet } from "@common/Image"
import "./parallax.scss"

const img =
  "https://greendjinn.api.localhost-group.com/wp-content/uploads/2020/03/mar.jpg"

const onScroll = ({ height, width }, { x, y }, ref) => {
  if (ref.current) {
    const { offsetTop, offsetLeft, offsetWidth, offsetHeight } = ref.current
    if (y > offsetTop + offsetHeight) {
      return "50%"
    } else {
      const diff = ((offsetTop - y) / (offsetTop + offsetHeight)) * 100
      return 50 + diff + "%"
    }
  } else {
    return "50%"
  }
}

const getProperImgSize = memoize((image, { width }) => {
  let fittedImg
  const sortedSrcSet = getSortedSrcSet(image.srcSet)

  for (const [properImgSize, _, sizes] of sortedSrcSet) {
    if (sizes && sizes[0] >= width) {
      fittedImg = `url(${properImgSize})`
      break
    }
  }

  return fittedImg || `url(${img})`
})

const ParallaxInner = ({ children, value, image, height }) => {
  const ref = useRef(null)

  const heightObj = height ? { height: height, minHeight: height } : {}

  const style = {
    backgroundImage: image.srcSet
      ? getProperImgSize(image, value.window)
      : `url(${img})`,
    // backgroundSize: '100%',
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "50%",
    backgroundPositionY: onScroll(value.window, value.scroll, ref),
    ...heightObj,
  }

  return (
    <div ref={ref} className={"parallax"} style={style}>
      <div>{children}</div>
    </div>
  )
}

const Parallax = ({ image = img, children, height }) => {
  return (
    <WindowCtx.Consumer>
      {value => (
        <ParallaxInner image={image} value={value} height={height}>
          {children}
        </ParallaxInner>
      )}
    </WindowCtx.Consumer>
  )
}

export default Parallax
